import { useEffect } from 'react';
import { useAppDispatch, markCompleted } from 'app/store';
import { calculateAllTopicsProgress } from 'app/helpers';

import type { data } from 'app';
import type { ProfileData } from 'app/store/profileSlice';

export const useMarkTopicCompletedEffect = (topicKeys: data.TopicWithActivityKeys[], data: ProfileData) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    // topic is already completed
    const { user, topics: userTopics } = data;
    if (user.topicCompletedAt) {
      return;
    }

    const { total, completed } = calculateAllTopicsProgress(topicKeys, userTopics);
    if (total > 0 && completed / total === 1) {
      dispatch(markCompleted({ scope: 'topic' }));
    }
  }, [data, dispatch, topicKeys]);
};
