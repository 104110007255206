import { useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { Breadcrumbs, Container, Typography, Box, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'components';
import { useAppDispatch, setEntryVisited, updateTopic, useAppSelector } from 'app/store';
import { ensureAuth } from 'app/helpers';
import { NavPage, GlobalFooter, SitePage } from 'app/layout';
import { InfoPage } from 'app/layout/InfoPage';
import { TopicContent, TopicNav } from 'app/topics';
import { navToContent } from 'app/topics/TopicNav';
import { TopicSeqNav, useSeqNav } from 'app/topics/TopicSeqNav';
import { useMarkTopicCompletedEffect } from './useMarkTopicCompletedEffect';
import { useFulfillment } from './useFulfillment';
import { AppBar } from 'app/layout/AppBar';
import * as analytics from 'app/analytics';

import type { data, state } from 'app';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

type TopicContentTemplateProps = {
  topic: Pick<data.Topic, 'key' | 'title' | 'description' | 'contents' | 'cover'>;
  content: Pick<data.Content, 'key' | 'title' | 'body'>;
  userTopic: state.Topic;
};

function TopicContentTemplate(props: TopicContentTemplateProps) {
  const { topic, content, userTopic } = props;
  const dispatch = useAppDispatch();
  const [fulfillment, topicProgress] = useFulfillment(topic, userTopic);

  // update last visited topic entry
  useEffect(() => {
    if (userTopic.lastVisited !== content.key) {
      dispatch(updateTopic({ topicKey: topic.key, data: { lastVisited: content.key } }));
    }
    analytics.selectContentEvent('content', [topic.key, content.key].join('/'));
  }, [topic.key, content.key]);

  // update progress if the topic progress is changed
  useEffect(() => {
    if (!isEqual(userTopic.progress, topicProgress)) {
      dispatch(updateTopic({ topicKey: topic.key, data: { progress: topicProgress } }));
    }
  }, [topicProgress]);

  // set visited if the topicEntry has never been visited
  useEffect(() => {
    if (fulfillment[content.key]?.completed === 0) {
      dispatch(setEntryVisited({ topicKey: topic.key, entryKey: content.key }));
    }
  }, [fulfillment]);

  const contents = useMemo(() => {
    return topic.contents.sort((a, b) => a.seq - b.seq);
  }, [topic.contents]);
  const [prev, next] = useSeqNav(contents, content.key);

  return (
    <NavPage
      drawerContent={
        <TopicNav
          userTopic={userTopic}
          topic={topic}
          contents={contents}
          activeKey={content.key}
          fulfillment={fulfillment}
        />
      }>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <AppBar />
        <Box sx={{ overflow: 'auto' }} component="main" flex={1}>
          <Box sx={{ minHeight: '100vh' }}>
            <Container sx={{ pt: 3 }}>
              <Breadcrumbs sx={{ display: { xs: 'none', sm: 'unset' } }} aria-label="breadcrumb">
                <Link color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Typography color="textPrimary">
                  {topic.title} - <em>{content.title}</em>
                </Typography>
              </Breadcrumbs>
              <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'space-between', alignItems: 'center' }}>
                <IconButton color="primary" onClick={navToContent(topic.key, prev)} disabled={prev === null}>
                  <ArrowBackIosNewIcon />
                </IconButton>
                <Typography>
                  {(topic.contents.findIndex((c) => content.key === c.key) ?? 0) + 1} / {topic.contents.length}
                </Typography>
                <IconButton color="primary" onClick={navToContent(topic.key, next)} disabled={next === null}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
              <TopicContent topic={topic} content={content} userTopic={userTopic} />
              <TopicSeqNav topic={topic} contents={contents} activeKey={content.key} />
            </Container>
          </Box>
          <GlobalFooter />
        </Box>
      </Box>
    </NavPage>
  );
}

export type ContentProps = {
  data: { topic: data.Topic; content: data.Content };
  pageContext: { topics: data.TopicWithActivityKeys[] };
};

/**
 * Statefull content template with all the user state loaded
 */
export const StatefulContent = ensureAuth(({ data: { topic, content }, pageContext: { topics } }: ContentProps) => {
  const profileData = useAppSelector((state) => state.profile.data!);
  useMarkTopicCompletedEffect(topics, profileData);

  const userTopic = profileData.topics[topic.key];
  if (!userTopic) {
    return <InfoPage>Content was not initialized</InfoPage>;
  }
  const coverImage = getImage(topic.cover.localFile);

  if (userTopic.locked) {
    return (
      <SitePage>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100vh - 10rem)',
            gap: 1,
            color: 'darkPrimary.main',
            width: 'min(40rem, 90%)',
          }}>
          <Box sx={{ width: { xs: '80%', sm: '50%' } }}>
            {coverImage ? <GatsbyImage image={coverImage} alt={`${topic.title} cover image`} /> : null}
          </Box>
          <Typography textAlign="center" variant="h5" color="inherit">
            The module <strong>{topic.title}</strong> is locked.
          </Typography>
          <Typography textAlign="center">
            Please unlock the module on your dashboard, by clicking the padlock icon.
          </Typography>
          <Link href="/dashboard#topics" textAlign="center">
            Go to dashboard
          </Link>
        </Container>
      </SitePage>
    );
  }
  return <TopicContentTemplate topic={topic} content={content} userTopic={userTopic} />;
});
