import { ComponentType, ComponentProps, useMemo } from 'react';
import { graphql } from 'gatsby';
import { StatefulContent } from './StatefulContent';
import { StatelessContent } from './StatelessContent';

/**
 * Validate token and select corresponding component.
 * @param Stateless
 * @param Stateful
 * @returns
 */
const withToken = <T extends {}>(Stateless: ComponentType<T>, Stateful: ComponentType<T>) => {
  return (props: ComponentProps<typeof Stateful> & { location: Location }) => {
    const { location } = props;
    const hasToken = useMemo(() => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      return token && token === process.env.GATSBY_COACH_TOKEN;
    }, [location]);

    return hasToken ? <Stateless {...props} /> : <Stateful {...props} />;
  };
};

export default withToken(StatelessContent, StatefulContent);

export const query = graphql`
  query ($contentId: String, $topicId: String!) {
    topic: strapiTopic(id: { eq: $topicId }) {
      key: slug
      title
      description
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      contents {
        key: slug
        seq
        title
        goals
        optional
        activityKeys {
          data: strapi_json_value
        }
      }
    }
    content: strapiContent(id: { eq: $contentId }) {
      key: slug
      title
      body {
        data {
          childMdx {
            body
          }
        }
      }
    }
  }
`;
