import { useMemo } from 'react';

import type { data, state, Progress } from 'app';
import { calculateFulfillment } from 'app/helpers';

export type TopicFulfillment = Record<string, Progress>;

function getFullfillmentProgress(fulfillment: TopicFulfillment, topicProgress: Progress): Progress {
  return Object.entries(fulfillment).reduce<Progress>((progress, [entryKey, entryProgress]) => {
    return { total: progress.total + entryProgress.total, completed: progress.completed + entryProgress.completed };
  }, topicProgress);
}

export const useFulfillment = (
  topic: Pick<data.Topic, 'contents'>,
  userTopic: state.Topic,
): [TopicFulfillment, Progress] => {
  return useMemo(() => {
    const fulfillment = calculateFulfillment(topic, userTopic);
    const topicProgress = getFullfillmentProgress(fulfillment, {
      total: 0,
      completed: 0,
    });
    return [fulfillment, topicProgress];
  }, [topic, userTopic]);
};
